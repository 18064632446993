<template>
  <div class="appendix">
    <div v-html="content" class="content">
    </div>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, ref, computed,
} from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';
import text from './text';

export default {
  name: 'appendix',
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      content: text.futures,
    };
  },
  methods: {
    async before() {
      const httprequest = this.isAddAccount
        ? this.$addapi.updateAccountAgreementStatus : this.$api.postChooseStepOne;
      const res = await httprequest({ ...this.submitForm, futures_agreement: '是' });
      if (res) {
        this.$router.back();
      }
    },
  },
  setup() {
    const isDisabled = ref(false);
    const submitForm = ref({});
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit('setTitleValue', '期货客户交易协议');
      store.commit('setStepsShow', false);
      const httprequest = isAddAccount.value
        ? httpAddApi.getAccountAgreementStatus : httpApi.getChooseStepOne;
      const result = await httprequest();
      const { data = {} } = result;
      isDisabled.value = data.futures_agreement === '是';
      submitForm.value = data;
      httpApi.formLog(null, isDisabled);
    });
    return {
      isDisabled,
      submitForm,
      isAddAccount,
    };
  },
};

</script>

<style lang="less">
  .appendix {
    margin-top: @space-2;
    text-align: left;

    .content {
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
